import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Footer = (props) => {
  const today = new Date();

  const data = useStaticQuery(graphql`
    query FooterQuery {
      configJson {
        logo {
          desktop
          desktop_height
          footer
          alt
        }
      }
    }
  `);

  const { alt, desktop, desktop_height, footer } = data.configJson.logo;
  return (
    <div className='footer'>
      <div className='footer-inner'>
        <div className='col-12 col-lg-6 text-center text-lg-left logo-wrapper'>
          <img
            height={desktop_height}
            alt={alt}
            src={desktop}
            className='desktop-logo'
          />
          <img height='86px' alt={alt} src={footer} className='mobile-logo' />
        </div>
        <div className='col-12 col-lg-6 text-center text-lg-right text-wrapper'>
          <p>© {today.getFullYear()} Namu AI. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
