import React, { useContext } from 'react';
import SEO from './SEO';
import Footer from './Footer';

import '../scss/style.scss';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

const Layout = (props) => {
  const { language } = useContext(I18nextContext);
  return (
    <>
      <SEO />
      <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ''}`}>
        <div id='wrapper' className='wrapper' lang={language}>
          {props.children}
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Layout;
