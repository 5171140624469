import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const SEO = (props) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        return (
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
            title={props.title}
            titleTemplate={
              props.title ? `%s` : `%s - ${data.site.siteMetadata.title}`
            }
          >
            <link rel='icon' type='image/x-icon' href='favicon.ico' />
            <link
              rel='apple-touch-icon'
              sizes='152x152'
              href='apple-touch-icon.png'
            />
            <link
              rel='icon'
              type='image/png'
              sizes='32x32'
              href='favicon-32x32.png'
            />
            <link
              rel='icon'
              type='image/png'
              sizes='16x16'
              href='favicon-16x16.png'
            />
          </Helmet>
        );
      }}
    />
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
